@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
a {
  text-decoration: none;
}

.eng {
  font-family: "almoni_regular_eng", sans-serif;
  font-size: 2.65625vw;
}

.eng2 {
  margin-right: 6px;
  font-family: "almoni_regular_eng", sans-serif;
  font-size: 1.9270833333vw;
}

.intro {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  z-index: 2;
  text-align: center;
  color: black;
}
.intro.appear, .intro.enter {
  opacity: 0;
}
.intro.appearing, .intro.entering {
  opacity: 1;
  transition: opacity;
  transition-duration: transitionIn;
}
.intro.exit {
  opacity: 1;
}
.intro.exiting {
  opacity: 0;
  transition: opacity;
  transition-duration: transitionOut;
}
.intro .top-info {
  padding-top: 3.6979166667vw;
  margin-bottom: 3.4114583333vw;
  background-color: #254d75;
}
.intro .top-info .arrowImg {
  width: 1.3020833333vw;
  height: 1.3020833333vw;
  margin-right: 0.5208333333vw;
}
.intro .top-info .top-info-wrapper {
  width: 100%;
  max-width: 80.7291666667vw;
  margin: 0 auto;
}
.intro .top-info .top-info-wrapper h1 {
  margin-bottom: 0;
  font-size: 5.2083333333vw;
  line-height: 7.65625vw;
  font-family: "almoni_bold", sans-serif;
  color: #fff;
}
.intro .top-info .top-info-wrapper .top-text p {
  font-size: 2.0833333333vw;
  font-family: "almoni_regular", sans-serif;
  color: #fff;
  line-height: 2.6041666667vw;
  direction: rtl;
}
.intro .top-info .top-info-wrapper .topLinkBlock {
  display: flex;
  padding: 0.7552083333vw 0vw;
  margin-top: 1.2760416667vw;
  margin-bottom: 2.34375vw;
  justify-content: center;
  align-items: center;
  font-size: 1.5625vw;
  color: #fff;
  font-family: "almoni_regular", sans-serif;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.intro .top-info .top-info-wrapper .topLinkBlock p {
  margin-bottom: 0;
}
.intro .top-info .top-info-wrapper .topLink {
  display: flex;
  margin-right: 0.5208333333vw;
  justify-content: center;
  align-items: center;
  color: #adf8de;
}
.intro .top-info .top-info-wrapper .linkButton {
  position: relative;
  bottom: -1.9791666667vw;
  padding: 0.3125vw 3.90625vw;
  background-color: #cc4b4b;
  font-size: 2.2916666667vw;
  color: #fff;
}
.intro .top-info .top-info-wrapper .linkBlock {
  display: flex;
  justify-content: center;
}

.iframe {
  display: block;
  width: 100%;
  height: 1000px;
  overflow: hidden;
}
.iframe__wrapper {
  height: 100%;
}
.iframe__textBlock {
  text-align: right;
}
.iframe__title {
  margin-bottom: 0;
  font-size: 2.6041666667vw;
  color: #576676;
  font-family: "almoni_bold", sans-serif;
}
.iframe__subTitle {
  margin-bottom: 0;
  font-size: 1.5625vw;
  color: #456;
}
.iframe__text {
  margin-bottom: 0;
  direction: rtl;
  font-size: 1.25vw;
  font-family: "almoni_light", sans-serif;
}
.iframe__block {
  padding: 0vw 1.0416666667vw;
}

@media (max-width: 575.98px) {
  .eng {
    font-size: 6.5625vw;
  }
  .eng2 {
    margin-right: 3px;
    font-size: 5.3125vw;
  }
  .iframe {
    height: 712px;
  }
  .iframe__title {
    font-size: 6.9444444444vw;
  }
  .iframe__subTitle {
    font-size: 5.5555555556vw;
  }
  .iframe__block {
    padding: 7.8125vw 4.0625vw 0vw 4.0625vw;
  }
  .secondIframe {
    height: 575px;
  }
  .intro .top-info {
    padding-top: 19.4444444444vw;
  }
  .intro .top-info .top-text p {
    font-size: 5.5555555556vw;
  }
  .intro .top-info .arrowImg {
    width: 3.4375vw;
    height: 3.4375vw;
    margin-right: 3.125vw;
  }
  .intro .top-info .top-info-wrapper {
    max-width: 87.5vw;
  }
  .intro .top-info .top-info-wrapper .topLinkBlock {
    display: flex;
    padding: 2.1875vw 0;
    margin-top: 3.125vw;
    margin-bottom: 0;
    flex-direction: column-reverse;
    font-size: 4.375vw;
  }
  .intro .top-info .top-info-wrapper .linkButton {
    bottom: -5.625vw;
    padding: 1.875vw 7.8125vw;
    font-size: 5vw;
  }
  .intro .top-info .top-info-wrapper h1 {
    margin-bottom: 3.125vw;
    font-size: 9.7222222222vw;
  }
  .intro .top-info .top-info-wrapper .top-text p {
    font-size: 5vw;
    line-height: 5.9375vw;
  }
}