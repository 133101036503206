@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.button {
  z-index: 50;
  width: 2.0833333333vw;
  margin-right: 1.5625vw;
  transition: none;
  border-radius: 50%;
}
.button img {
  display: block;
  width: 100%;
}
.button svg {
  width: 100%;
  height: 100%;
}

:global body.acc-grayscale, :global body.acc-negative {
  overflow-x: hidden;
}
:global div#accessibility {
  position: absolute !important;
  z-index: 45 !important;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}
@media only screen and (min-width: 551px) {
  :global div#accessibility {
    top: 29px !important;
  }
  html[dir=ltr] :global div#accessibility {
    right: 65px !important;
    left: auto !important;
  }
  html[dir=rtl] :global div#accessibility {
    right: auto !important;
    left: 65px !important;
  }
}
@media only screen and (max-width: 551px) {
  :global div#accessibility {
    top: auto !important;
    right: 6.25vw !important;
    bottom: 9.375vw !important;
    left: auto !important;
    z-index: 45 !important;
  }
}
:global div#accessibility div#acc-header .image {
  background: none !important;
}
@media only screen and (max-width: 551px) {
  :global div#accessibility div#acc-header .image {
    right: 0 !important;
    left: auto !important;
  }
  html[dir=rtl] :global div#accessibility div#acc-header .image {
    right: 0 !important;
    left: auto !important;
  }
}
:global div#accessibility div#acc-header .image::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 25px;
  height: 25px;
  transform: translate(-50%, -50%);
}
html[dir=ltr] :global div#accessibility div#acc-header .image {
  right: 0 !important;
  left: auto !important;
}
html[dir=rtl] :global div#accessibility div#acc-header .image {
  right: auto !important;
  left: 0 !important;
}
:global div#accessibility div#acc-header .image img {
  display: none !important;
}
:global div#accessibility div#acc-header .text {
  visibility: hidden;
}
@media only screen and (min-width: 551px) {
  :global div#accessibility button#acc-regular:focus {
    outline: transparent auto 5px !important;
  }
}
:global div#accessibility div#acc-footer {
  display: none !important;
}
:global input:-webkit-autofill,
:global textarea:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
  color: black;
}
:global .overflow {
  overflow: hidden;
}
:global #INDmenu-btn::before, :global #INDmenu-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
@media only screen and (max-width: 551px) {
  :global #INDmenu-btn::before, :global #INDmenu-btn::after {
    width: 14.375vw;
    height: 14.375vw;
    background-color: white;
  }
}
:global #INDbtnWrap #INDmenu-btn {
  position: fixed;
  top: 1.0416666667vw !important;
  right: initial;
  left: 1.0416666667vw !important;
  width: 2.0833333333vw;
  height: 2.0833333333vw;
  margin: 0;
  transition: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}
@media only screen and (max-width: 551px) {
  :global #INDbtnWrap #INDmenu-btn {
    position: absolute;
    top: 5.9375vw !important;
    left: 6.25vw !important;
    width: 13.75vw;
    height: 13.75vw;
  }
}
:global #INDmenu-btn svg {
  visibility: hidden;
}

@media (max-width: 575.98px) {
  .button {
    width: 6.9444444444vw;
    margin-right: 1.5625vw;
  }
}