.rabbi-logo {
  position: relative;
  width: 20px;
  height: 28px;
  overflow: hidden;
  transition: 0.25s ease;
}
@media screen and (min-width: 1024px) {
  .rabbi-logo:hover {
    width: 90px;
  }
}
.rabbi-logo__link {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 90px;
  height: 28px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  text-decoration: none;
}
.rabbi-logo__text {
  margin-top: -1px;
  margin-left: 7px;
  font-size: 10px;
  line-height: 11px;
}
.rabbi-logo__icon {
  display: inline-block;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rabbi-logo__icon {
    width: 20px;
    height: 32.6984126984px;
  }
}
.rabbi-logo.dark a {
  color: black;
}
.rabbi-logo.dark .r {
  fill: black;
}
.rabbi-logo.light a {
  color: white;
}
.rabbi-logo.light .r {
  fill: white;
}